import React from 'react';
import ChildEdit from '../../child/component/ChildEdit';

function Onboarding() {
    return (
        <ChildEdit isOnboarding={true}/>
    );
}

export default Onboarding;
