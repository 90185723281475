export function getClosingDayText(value, t) {
    if (value.locked) {
        if (value.closingDayFromInstitution) {
            return t('MealPlan.CLOSED');
        } else if (value.closingDayFromCaterer) {
            return t('MealPlan.CATERER_CLOSED');
        } else if (value.closingDayFromGroup) {
            return t('MealPlan.GROUP_CLOSED');
        }
    }
    return t('MealPlan.NOT_AVAILABLE');
}
